import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import transform from 'chipDataTransform';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';
import Recommend from '../../../components/recommend';
import TopLogo from '../topLogo';

class TopNav extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染网页布局
     */
    render() {
        /**
         * 组件分发数据
         */
        const { content } = this.props;

        const { topNav, topLink, topLogo } = content;

        const creatList = () => (
            <ul className={styles.menuList}>
                {topNav.map((item, index) => (
                    <li key={index}>
                        <a href={item.url} target="_blank" rel={rel}>
                            <span>{item.title}</span>
                        </a>
                    </li>
                ))}
            </ul>
        );

        return (
            <div>
                <div>
                    <Chip id="162653" type="static" title="头部Logo" groupName="top" content={topLogo}>
                        <TopLogo content={topLogo} />
                    </Chip>
                </div>

                <div className={styles.menu}>
                    <Chip id="45004" type="struct" title="顶部导航" groupName="top" content={topNav}>
                        {creatList()}
                    </Chip>
                    <Chip id="30004" type="static" title="顶部链接" groupName="top" content={topLink}>
                        <Recommend content={topLink} />
                    </Chip>
                </div>
            </div>
        );
    }
}

export default errorBoundary(TopNav);
