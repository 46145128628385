import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Title from '../../../../components/title';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';

class NewsActivity extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    /**
     * 渲染网页布局
     */
    render() {
        /**
         * 组件分发数据
         */
        const { content } = this.props;
        const titleData = {
            title: '新闻与活动',
            url: '//biz.ifeng.com/shanklist/31-60081-60096-',
        };

        const creatList = () => (
            <div className={styles.dl_list_01}>
                {content.map((item, index) => (
                    <dl key={index}>
                        <dt>
                            <a href={item.url}>
                                <img src={item.thumbnail} alt={item.title} />
                            </a>
                        </dt>
                        <dd>
                            <h2>
                                <a href={item.url}>{item.title}</a>
                            </h2>
                            <div className={styles.text}> {item.summary}</div>
                            <a href={item.url} className={styles.links}>
                                查看详情
                            </a>
                            <span>{item.newsTime.slice(0, 10)}</span>
                        </dd>
                    </dl>
                ))}
            </div>
        );

        return (
            <div className={styles.block_left_03}>
                <Title content={titleData} />
                {creatList()}
            </div>
        );
    }
}

export default errorBoundary(NewsActivity);
