import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Banner from './banner';
import BannerRight from './bannerRight';
class Block1 extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染网页布局
     */
    render() {
        /**
         * 组件分发数据
         */
        const { content } = this.props;
        const { bannerPic, bannerPicRight } = content;

        return (
            <div>
                <div className={styles.block_left}>
                    <Chip id="285987" type="recommend" title="焦点图左侧" groupName="content" content={bannerPic}>
                        <Banner />
                    </Chip>
                </div>
                <Chip id="60006" type="recommend" title="焦点图右侧" groupName="content" content={bannerPicRight}>
                    <BannerRight content={bannerPicRight} />
                </Chip>
            </div>
        );
    }
}

export default errorBoundary(Block1);
