import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';
import Block1 from './block1';
import Block2 from './block2';
import NewsActivity from './newsActivity';
import RelatedInfo from './relatedInfo';

class Content extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染网页布局
     */
    render() {
        /**
         * 组件分发数据
         */
        const { content } = this.props;
        const { bannerPic, bannerPicRight, bannerPicBottom, newCase, newsActivity, contactInfo } = content;

        const block1Data = {
            bannerPic,
            bannerPicRight,
        };
        const block2Data = {
            bannerPicBottom,
            bannerPic,
            newCase,
        };

        return (
            <div className={styles.content}>
                <Block1 content={block1Data} />
                <div className={styles.clear} />
                <Block2 content={block2Data} />
                <NewsActivity content={newsActivity} />
                <Chip id="30005" type="static" title="联系信息" groupName="content" content={contactInfo}>
                    <RelatedInfo content={contactInfo} />
                </Chip>
                <div className={styles.boxBot} />
            </div>
        );
    }
}

export default errorBoundary(Content);
