import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import PicSlide from './picSlide';
import NewCase from './newCase';
class Block2 extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染网页布局
     */
    render() {
        /**
         * 组件分发数据
         */
        const { content } = this.props;
        const { bannerPic, newCase, bannerPicBottom } = content;

        return (
            <div className={styles.block2_container}>
                <Chip id="60007" type="recommend" title="焦点图下方" groupName="content" content={bannerPicBottom}>
                    <PicSlide content={bannerPicBottom} />
                </Chip>
                <NewCase content={newCase} />
                {/* 新闻 */}
            </div>
        );
    }
}

export default errorBoundary(Block2);
