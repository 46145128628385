import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Slides from '@ifeng-fe/ui_pc_slides';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';
import { formatNewsTime } from '@ifeng-fe/container_channel/common/client/winter/utils/formatNewsTime';

class NewCaseSlide extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    sliderTmpl = list => {
        return (
            <ul>
                {list && list.map
                    ? list.map((item, index) => (
                          <li key={index}>
                              <a href={item.url} rel={rel} imgindex={index} target="_blank">
                                  <img src={item.src[0]} width="180px" height="135px" />
                                  <h2 className={styles.title}>{item.title}</h2>
                              </a>
                              <span>{item.newsTime.slice(0, 10)}</span>
                          </li>
                      ))
                    : ''}
            </ul>
        );
    };

    /**
     * 渲染网页布局
     */
    render() {
        /**
         * 组件分发数据
         */
        const { content } = this.props;

        const newList = [content.slice(0, 5), content.slice(5, 10)];

        const config = {
            arrows: true,
            dots: false,
            dotsAction: 'hover',
            autoplay: false,
            autoplayInterval: 6000,
            speed: 1000,
            sliderTmpl: this.sliderTmpl,
            dotStyle: styles.dot,
            dotCurrentStyle: styles.current,
        };

        // console.log(newList);

        return (
            <div className={styles.vtroll}>
                <div className={styles.vask}>
                    <Slides content={newList} config={config} />
                </div>
            </div>
        );
    }
}

export default errorBoundary(NewCaseSlide);
