import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import rel from '@ifeng-fe/container_channel/common/client/utils/rel';
class bannerRight extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    /**
     * 渲染网页布局
     */
    render() {
        /**
         * 组件分发数据
         */
        const { content } = this.props;

        // const [ item1, item2] = bannerPicRight;

        const createList = () => (
            <div>
                {content.map((item, index) => (
                    <div className={styles[`imgCon0${index + 1}`]} key={index}>
                        <a href={item.url} target="_blank" rel={rel}>
                            <img src={item.thumbnail} />
                        </a>
                        <span className={styles.textbg} />
                        <p className={styles.text}>
                            <a href={item.url} target="_blank" rel={rel}>
                                {item.title}
                            </a>
                        </p>
                    </div>
                ))}
            </div>
        );

        return <div className={styles.block_left_02}>{createList()}</div>;
    }
}

export default errorBoundary(bannerRight);
