import { jsonp } from '@ifeng-fe/ui_base';
import { formatImage, formatUrl } from '@ifeng-fe/public_method';

// apiUrl为webpack注入的全局变量
/* eslint-disable */
let apiBaseUrl = apiUrl;

/* eslint-enable */

if (typeof window !== 'undefined' && /\.shank\.ifeng\.com/.test(window.location.hostname)) {
    apiBaseUrl = '/api';
}

// 商业��页提交预约信息
export const submitAdvisory = async paramsObj => {
    const specialname_city = 'all';
    const specialname_dealer = '';
    const specialname_user = 'yuanshengyingxiao';
    const specialcname = '原生营销';
    const { userName, company, purpose, mobile, province, city } = paramsObj;

    try {
        return await jsonp('http://miniactive.ifeng.com/2017/car/index.php', {
            data: {
                _c: 'car',
                _a: 'register',
                specialname_city,
                specialname_dealer,
                specialname_user,
                specialcname,
                userName,
                mobile,
                province,
                city,
                interested: company,
                buyCarTime: purpose,
                format: 'js',
            },
            jsonpCallback: 'registerStatus',
            cache: false,
        });
    } catch (e) {
        console.error(e);
    }
};
