import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Recommend from '../../../../components/recommend';
class RelatedInfo extends React.PureComponent {
    static propTypes = {
        content: PropTypes.string,
    };

    /**
     * 渲染网页布局
     */
    render() {
        /**
         * 组件分发数据
         */
        const { content } = this.props;

        return (
            <div>
                <Recommend content={content} />
            </div>
        );
    }
}

export default errorBoundary(RelatedInfo);
