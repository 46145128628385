import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { submitAdvisory } from '../../../../services/api';

class Affix extends React.PureComponent {
    static propTypes = {
        // content: PropTypes.object,
    };

    state = {
        qrcodeShow: false,
        advisoryShow: false,
        userName: '',
        company: '',
        purpose: '品牌广告资讯',
        province: '',
        city: '',
        mobile: '请输入手机号',
        provinceArr: [
            '北京',
            '上海',
            '天津',
            '重庆',
            '河北',
            '山西',
            '内蒙',
            '辽宁',
            '吉林',
            '黑龙江',
            '江苏',
            '浙江',
            '安徽',
            '福建',
            '江西',
            '山东',
            '河南',
            '湖北',
            '湖南',
            '广东',
            '广西',
            '海南',
            '四川',
            '贵州',
            '云南',
            '陕西',
            '甘肃',
            '青海',
            '宁夏',
            '新疆',
            '西藏',
            '香港',
            '澳门',
            '台湾',
            '海外',
            '其他',
        ],
        cityArr: {
            北京: '海淀,朝阳,东城,西城,崇文,宣武,丰台,石景山,房山,大兴,通州,顺义,昌平,密云,怀柔,延庆,平谷,门头沟',
            上海: '闵行,徐汇,长宁,静安,卢湾,虹口,黄浦,浦东新区,杨浦,闸北,普陀,嘉定,宝山,青浦,奉贤,南汇,崇明,金山,松江',
            天津: '和平,河东,河西,南开,河北,红桥,塘沽,汉沽,大港,东丽,西青,北辰,津南,武清,宝坻,静海,宁河,蓟县,开发区',
            重庆:
                '渝中,大渡口,江北,沙坪坝,九龙坡,南岸,北碚,万盛,双桥,渝北,巴南,万州,涪陵,黔江,长寿,江津,合川,永川,南川,綦江,潼南,铜梁,大足,荣昌,璧山,梁平,城口,丰都,垫江,武隆,忠县,开县,云阳,奉节,巫山,巫溪,石柱,秀山,酉阳,彭水',
            河北: '石家庄,唐山,秦皇岛,邯郸,邢台,保定,张家口,承德,沧州,廊坊,衡水,其他',
            山西: '太原,大同,阳泉,长治,晋城,朔州,其他',
            内蒙: '呼和浩特,包头,乌海,赤峰,通辽,包头,乌海,赤峰,通辽,其他',
            辽宁: '沈阳,大连,鞍山,抚顺,本溪,丹东,锦州,营口,阜新,辽阳,盘锦,铁岭,朝阳,葫芦岛,其他',
            吉林: '长春,吉林,四平,辽源,通化,白山,松原,白城,其他',
            黑龙江: '哈尔滨,齐齐哈尔,鸡西,鹤岗,双鸭山,大庆,伊春,佳木斯,七台河,牡丹江,黑河,其他',
            江苏: '南京,无锡,徐州,常州,苏州,南通,连云港,淮阴,盐城,扬州,镇江,泰州,宿迁,其他',
            浙江: '杭州,宁波,温州,嘉兴,湖州,绍兴,金华,衢州,舟山,台州,其他',
            安徽: '合肥,芜湖,蚌埠,淮南,马鞍山,淮北,铜陵,安庆,黄山,滁州,阜阳,宿州,巢湖,六安,其他',
            福建: '福州,厦门,莆田,三明,泉州,漳州,南平,龙岩,其他',
            江西: '南昌,景德镇,萍乡,九江,新余,鹰潭,赣州,其他',
            山东: '济南,青岛,淄博,枣庄,东营,烟台,潍坊,济宁,泰安,威海,日照,莱芜,临沂,德州,聊城,其他',
            河南: '郑州,开封,洛阳,平顶山,安阳,鹤壁,新乡,焦作,濮阳,许昌,漯河,三门峡,南阳,商丘,信阳,其他',
            湖北: '武汉,黄石,十堰,宜昌,襄樊,鄂州,荆门,孝感,荆州,黄冈,咸宁,其他',
            湖南: '长沙,株洲,湘潭,衡阳,邵阳,岳阳,常德,张家界,益阳,郴州,永州,怀化,娄底,其他',
            广东:
                '广州,韶关,深圳,珠海,汕头,佛山,江门,湛江,茂名,肇庆,惠州,梅州,汕尾,河源,阳江,清远,东莞,中山,潮州,揭阳,云浮,其他',
            广西: '南宁,柳州,桂林,梧州,北海,防城港,钦州,贵港,玉林,其他',
            海南: '海口,三亚,其他',
            四川: '成都,自贡,攀枝花,泸州,德阳,绵阳,广元,遂宁,内江,乐山,南充,宜宾,广安,达州,其他',
            贵州: '贵阳,六盘水,遵义,其他',
            云南: '昆明,曲靖,玉溪,其他',
            陕西: '西安,铜川,宝鸡,咸阳,渭南,延安,汉中,榆林,其他',
            甘肃: '兰州,嘉峪关,金昌,白银,天水,其他',
            青海: '格尔木,德令哈,其他',
            宁夏: '银川,石嘴山,吴忠,其他',
            新疆: '乌鲁木齐,克拉玛依,其他',
            西藏: '拉萨,其他',
            香港: '香港',
            澳门: '澳门',
            台湾: '台湾',
        },
    };

    /**
     * 回到顶部
     */
    backToTop = () => {
        scrollTo(0, 0);
    };
    /**
     * 二维码显示隐藏
     */
    handelQrcode = () => {
        this.setState({
            qrcodeShow: !this.state.qrcodeShow,
            advisoryShow: false,
        });
    };

    handelAdvisory = () => {
        this.setState({
            qrcodeShow: false,
            advisoryShow: !this.state.advisoryShow,
        });
    };

    handleUserNameChange = e => {
        this.setState({ userName: e.currentTarget.value });
    };

    handleCompanyChange = e => {
        this.setState({ company: e.currentTarget.value });
    };

    handlePurposeChange = e => {
        this.setState({ purpose: e.currentTarget.value });
    };

    handlemobileChange = e => {
        this.setState({ mobile: e.currentTarget.value });
    };
    handlemobileFocus = () => {
        const { mobile } = this.state;

        if (mobile === '请输入手机号') {
            this.setState({ mobile: '' });
        }
    };
    handlemobileBlur = () => {
        const { mobile } = this.state;

        if (mobile === '') {
            this.setState({ mobile: '请输入手机号' });
        }
    };

    handleProvinceChange = e => {
        this.setState({ province: e.currentTarget.value });
    };

    handleCityChange = e => {
        this.setState({ city: e.currentTarget.value });
    };

    // 回调函数
    registerStatus = str => {
        if (str === 1) {
            alert('恭喜您，提交成功！');
            // res();
        } else if (str === 2) {
            alert('该电话已提交！');

            return false;
        } else {
            alert('提交失败！');

            return false;
        }
    };

    // 预约提示信息 如果预约成功 则返回成功信息; 如果已经预约 则返回已预约提示信息;否则提示预约失败;
    handleSubmit = async () => {
        const { userName, company, purpose, mobile, province, city } = this.state;
        const paramsObj = { userName, company, purpose, mobile, province, city };

        const param = /^((13|15)|18|14|17|16)\d{9}$/;

        // 参数校验
        if (!userName || userName === '') {
            alert('请填写联系人姓名！');

            return false;
        }
        if (!company || company === '') {
            alert('请填写公司名称！');

            return false;
        }
        if (!purpose || purpose === '') {
            alert('请填写咨询目的');

            return false;
        }
        if (province === '选择省份' || province === '') {
            alert('请选择省份');

            return false;
        }
        if (city === '选择城市' || city === '') {
            alert('请选择城市');

            return false;
        }

        if (!mobile || mobile === '') {
            alert('请填写手机号码！');

            return false;
        } else if (!param.test(mobile)) {
            alert('请填写正确的手机号码！');

            return false;
        }

        const result = await submitAdvisory(paramsObj);

        this.registerStatus(result);

        // console.log()
    };
    // 重置提交表单

    /**
     * 渲染网页布局
     */
    render() {
        const { qrcodeShow, advisoryShow, userName, company, mobile, province, provinceArr, cityArr } = this.state;

        let citys = cityArr[province] || cityArr['天津'];

        citys = citys.split(',');

        const createProvinceOption = data => (
            <select className={styles.province} onChange={this.handleProvinceChange}>
                <option value>选择省份</option>
                {data.map((item, index) => (
                    <option value={item} key={index}>
                        {item}
                    </option>
                ))}
            </select>
        );
        const createCityOption = data => (
            <select className={styles.city} onChange={this.handleCityChange}>
                <option value>选择城市</option>
                {data.map((item, index) => (
                    <option value={item} key={index}>
                        {item}
                    </option>
                ))}
            </select>
        );
        /**
         * 组件分发数据
         */
        const bottomAffix = (
            <React.Fragment>
                <div className={styles.affix_box}>
                    <div className={qrcodeShow ? styles.qrcode_box : styles.noshow} />
                    <div className={advisoryShow ? styles.advisory_box : styles.noshow}>
                        <div className={styles.formItem}>
                            <span>联系人姓名</span>
                            <input
                                type="text"
                                value={userName}
                                className={styles.userName}
                                onChange={this.handleUserNameChange}
                            />
                        </div>
                        <div className={styles.formItem}>
                            <span>公司名称</span>
                            <input
                                type="text"
                                value={company}
                                className={styles.company}
                                onChange={this.handleCompanyChange}
                            />
                        </div>
                        <div className={styles.formItem}>
                            <span>咨询目的</span>
                            <select className={styles.purpose} onChange={this.handlePurposeChange}>
                                <option value="品牌广告咨询">品牌广告咨询</option>
                                <option value="凤羽效果广告资讯">凤羽效果广告资讯</option>
                                <option value="其他">其他</option>
                            </select>
                        </div>
                        <div className={styles.formItem}>
                            <span>公司所在地</span>
                            {createProvinceOption(provinceArr)}
                            {createCityOption(citys)}
                        </div>
                        <div className={styles.formItem}>
                            <input
                                type="text"
                                value={mobile}
                                className={styles.mobile}
                                onChange={this.handlemobileChange}
                                onFocus={this.handlemobileFocus}
                                onBlur={this.handlemobileBlur}
                            />
                            <input
                                type="button"
                                value="立即咨询"
                                className={styles.button}
                                onClick={this.handleSubmit}
                            />
                        </div>
                    </div>
                    <div className={styles.container}>
                        <div className={styles.backtop} onClick={this.backToTop} />
                        <div className={styles.advisory} onClick={this.handelAdvisory} />
                        <div className={styles.qrcode} onClick={this.handelQrcode} />
                    </div>
                </div>
            </React.Fragment>
        );

        return ReactDOM.createPortal(bottomAffix, document.body);
    }
}

export default errorBoundary(Affix);
