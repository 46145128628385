import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import ChipEdit from 'ChipEdit';
import transform from 'chipDataTransform';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import TopNav from './topNav';
import Content from './content';
import Footer from '@ifeng-fe/container_channel/common/client/winter/components/footer/';
import Affix from './affix';

class Layout extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    componentDidMount() {
        // 上报首屏时间
        if (window && window.BJ_REPORT) window.BJ_REPORT.firstScreen();
    }

    /**
     * 渲染���页布局
     */
    render() {
        /**
         * 组件分发数据
         */
        const {
            content: {
                topLink,
                topNav,
                topLogo,
                bannerPic,
                bannerPicRight,
                bannerPicBottom,
                newCase,
                contactInfo,
                newsActivity,
                footer,
            },
        } = this.props;

        const topData = { topLink, topNav, topLogo };

        const contentData = {
            bannerPic,
            bannerPicRight,
            bannerPicBottom,
            newCase,
            contactInfo,
            newsActivity,
        };

        return (
            <div className={styles.bodybg}>
                <TopNav content={topData} />

                <Content content={contentData} />
                <div className={styles.footer}>
                    <Chip id="20012" type="struct" title="底部公用版权" groupName="底部" content={footer}>
                        <Footer />
                    </Chip>
                </div>
                <Affix />
                <ChipEdit transform={transform} />
            </div>
        );
    }
}

export default errorBoundary(Layout);
