import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Title from '../../../../../components/title';
import NewCaseSlide from './newCaseSlide';

class NewCase extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    /**
     * 渲染网页布局
     */
    render() {
        /**
         * 组件分发数据
         */
        const { content } = this.props;
        const titleData = {
            title: '最新案例',
            url: '//biz.ifeng.com/shanklist/31-60081-60097-',
        };

        return (
            <div>
                <Title content={titleData} />
                {/* 幻灯 */}
                <NewCaseSlide content={content} />
            </div>
        );
    }
}

export default errorBoundary(NewCase);
