import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Slides from '@ifeng-fe/ui_pc_slides';

class Banner extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    /**
     * 渲染网页布局
     */
    render() {
        /**
         * 组件分发数据
         */
        const { content } = this.props;

        // console.log(content);

        // 幻灯title，src,url

        const config = {
            dots: false,
            arrows: true,
            autoplay: true,
            // dotStyle: styles.dot,
            // dotCurrentStyle: styles.current,
        };

        return (
            <div className={styles.slide_container}>
                <Slides content={content} config={config} />
            </div>
        );
    }
}

export default errorBoundary(Banner);
