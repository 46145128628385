import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import rel from '@ifeng-fe/container_channel/common/client/utils/rel';
class Title extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染网页布局
     */
    render() {
        /**
         * 组件分发数据
         */
        const { content } = this.props;
        const { title, url } = content;

        return (
            <div className={styles.block_item}>
                {title}
                <div className={styles.ht} />
                <a href={url} target="_blank" className={styles.ht_t} rel={rel} />
            </div>
        );
    }
}

export default errorBoundary(Title);
